<script setup lang="ts">
const props = defineProps<{
  src?: string | null;
}>();
</script>

<template>
  <img
    :src="props.src ? props.src : '/favicon.svg'"
    alt="EventCAT"
    class="inline-block"
  />
</template>
